<template>
  <form @submit.prevent="save" class="w-m-content suporte-chamado-window e-input-modern">
    <u-ajax-bar ref="bar" skip-hijack color="positive" />
    <div class="wrapper-lg">
      <div class="flex justify-between m-b-lg">
        <div><h2 class="title">Novo Chamado</h2></div>
        <div></div>
      </div>

      <e-row mr>
        <e-col>
          <erp-s-field required label="Tipo de atendimento">
            <type-task-select v-model="task.type" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field required label="Fila">
            <task-queue-select :project="projeto ? projeto.id : null" with-no-project v-model="task.queue" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t-sm" mr>
        <e-col>
          <erp-s-field label="Projeto">
            <project-select v-model="task.project" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Abrir esta requisição em nome de">
            <person-select v-model="task.cliente" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t-sm" mr>
        <e-col>
          <erp-s-field label="Resumo" required>
            <erp-input autofocus v-model="task.description" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t-sm" mr>
        <e-col>
          <erp-s-field label="Detalhes" required>
            <div class="task-editor">
              <!--        <text-editor toolbar-position="top-outside"/>-->
              <sl-editor
                  ref="editor"
                  v-model="task.text"
                  placeholder="Descreva em detalhes o pedido..."
                  :show-toolbar-on-focus="true"
                  :close-click-outside="false"
                  @focus="() => { textEditing = true }"
              />
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t" mr>
        <e-col>
            <task-attachments ref="attachments" :task="task" />
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right" style="position: sticky; bottom: 0; z-index: 2">
      <div class="flex justify-between">
        <div>
          <u-btn @click="$uloc.window.close($root.wid)" label="Fechar" no-caps
                 :color="'blue-8'"
                 class="b-radius-3px" />
        </div>
        <div>
          <u-btn type="submit" label="Criar chamado" no-caps
                 :color="'green'"
                 :disable="loading"
                 class="b-radius-3px" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ERow from '@/components/layout/components/Row'
import ECol from '@/components/layout/components/Col'
import {ErpCheckbox} from 'uloc-vue-plugin-erp'
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import TypeTaskSelect from "components/suporte/components/task/include/TypeSelect"
import {task} from "@/domain/tarefas/helpers/mock"
import TaskQueueSelect from "components/suporte/components/task/include/QueueSelect"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import ProjectSelect from "components/suporte/components/projetos/include/ProjectSelect"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import SlEditor from "@/components/layout/components/Editor"
import TaskAttachments from "components/suporte/components/task/include/attachment/Attachments"
import {date, UAjaxBar} from "uloc-vue"
import {newTask, updateTask} from "@/domain/tarefas/services"
import {datetimeToEn} from "@/utils/date"

export default {
  name: 'UsuarioWindowModern',
  provide: function () {
    return {
      container: this
    }
  },
  components: {
    TaskAttachments,
    ErpInput,
    ProjectSelect,
    PersonSelect,
    TaskQueueSelect,
    TypeTaskSelect,
    ErpSField,
    ERow,
    ECol,
    SlEditor,
    UAjaxBar
    // ErpCheckbox
  },
  props: ['options', 'projeto', 'cliente', 'router'],
  data () {
    const _task = JSON.parse(JSON.stringify(task))
    _task.project = this.projeto
    _task.cliente = this.projeto && this.projeto.client ? this.projeto.client : this.cliente
    return {
      window: this.$root.wid,
      loading: false,
      task: _task
    }
  },
  mounted () {
  },
  computed: {},
  methods: {
    transformToSend() {
      const task = JSON.parse(JSON.stringify(this.task))
      task.text = this.$refs.editor.htmlContent()
      if (task.deadline) {
        task.deadline = datetimeToEn(task.deadline)
      }
      if (task.startTime) {
        task.startTime = datetimeToEn(task.startTime)
      }
      if (task.participants && task.participants.length) {
        task.participants = task.participants.map(p => {
          return {
            person: p.person.id,
            user: p.user ? p.user.id : null,
            active: p.active
          }
        })
      }
      const types = [
          'cliente',
          'priority',
          'project',
          'queue',
          'type',
      ]
      types.map(t => {
        if (typeof task[t] !== 'undefined' && task[t] && task[t].id) {
          task[t] = task[t].id
        }
      })
      return task
    },
    save() {
      const task = this.transformToSend()
      this.loading = true
      this.$refs.bar.start()
      const method = this.task.id ? updateTask : newTask
      method(task)
          .then(async response => {
            console.log(response)
            this.loading = false
            this.$uloc.window.emit(this.window, 'created', response.data)
            this.$uloc.notify({
              color: this.task.id ? 'black' : 'positive',
              message: this.task.id ? `Tarefa atualizada` : `Tarefa criada com id #${response.data.id}`,
              position: 'bottom-left',
            })
            this.$refs.bar.stop()
            this.task = response.data
            const callback = () => {
              setTimeout(() => {
                if (this.task.project) {
                  this.router.push({
                    name: 'suporte.projeto.tarefa',
                    params: {id: this.task.project.id, tarefa: this.task.id}
                  })
                } else {
                  this.router.push({name: 'suporte.tarefa', params: {tarefa: this.task.id}})
                }
              }, 10)
              this.$uloc.window.close(this.window)
            }
            if (this.$refs.attachments.existsFilesToUpload) {
              await this.$refs.attachments.iniciaEnvios()
            }
            callback()
          })
          .catch(error => {
            this.loading = false
            this.$refs.bar.stop()
            this.alertApiError(error)
          })
    }
  }
}
</script>
